import { UserRole } from '../helpers/authHelper';

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const EXAMES = [
  {
    value: 'Teste rápido',
    label: 'Teste rápido',
  },
  { value: 'PCR (nasal/orofaringe)', label: 'PCR (nasal/orofaringe)' },
];

export const BOOL = [
  { value: 'Sim', label: 'Sim' },
  { value: 'Não', label: 'Não' },
];

export const RESULTADOS = [
  { value: 'Positivo', label: 'Positivo' },
  { value: 'Negativo', label: 'Negativo' },
  { value: 'Pendente', label: 'Pendente' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const adminRoot = '/administrador';
export const fornecedorRoot = '/fornecedor';
export const gestorRoot = '/gestor';

export const pathByProfile = {
  'Super-User': '/administrador',
  Administrador: '/administrador',
  Fornecedor: '/fornecedor',
  Gestor: '/gestor',
};

export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.bluenavy';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
