/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from '../constants/defaultValues';
import moment from 'moment';
import AWS from 'aws-sdk';
import { v4 } from 'uuid';
import bluebird from 'bluebird'

export function mascaraCpf(valor = '') {
  return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
}
export function mascaraCnpj(valor = '') {
  return valor.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5'
  );
}

export function downloadFile(fileURL, fileName) {
  // for non-IE
  if (!window.ActiveXObject) {
    var save = document.createElement('a');
    save.href = fileURL;
    save.target = '_blank';
    var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
    save.download = fileName || filename;
    if (
      navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
      navigator.userAgent.search('Chrome') < 0
    ) {
      document.location = save.href;
      // window event not working here
    } else {
      var evt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }

  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
    var _window = window.open(fileURL, '_blank');
    _window.document.close();
    _window.document.execCommand('SaveAs', true, fileName || fileURL);
    _window.close();
  }
}

export function formatToInputDate(date = '') {
  if (date) return moment(date).format('YYYY-MM-DD');
}

export function formatDate(date = '') {
  if (date) return moment(date).format('DD/MM/YYYY');
}

export function formatHour(date = '') {
  if (date) return moment(date).format('HH:mm');
}

export function formatDateTime(date = '') {
  if (date) return moment(date).format('DD/MM/YYYY HH:mm');
}

export function formatToUTC(date = '') {
  if (date) return moment(date).format();
}

export function numberToBRLCurrency(number = 0) {
  return number.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

export const cpfValidate = (cpf) => {
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++)
    soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
};

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};
export function cnpjValidate(value) {
  if (!value) return false;

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const validTypes =
    typeof value === 'string' ||
    Number.isInteger(value) ||
    Array.isArray(value);

  // Elimina valor em formato inválido
  if (!validTypes) return false;

  // Guarda um array com todos os dígitos do valor
  const match = value.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];

  // Valida a quantidade de dígitos
  if (numbers.length !== 14) return false;

  // Elimina inválidos com todos os dígitos iguais
  const items = [...new Set(numbers)];
  if (items.length === 1) return false;

  // Cálculo validador
  const calc = (x) => {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += n * factor--;
      if (factor < 2) factor = 9;
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };

  // Separa os 2 últimos dígitos de verificadores
  const digits = numbers.slice(12);

  // Valida 1o. dígito verificador
  const digit0 = calc(12);
  if (digit0 !== digits[0]) return false;

  // Valida 2o. dígito verificador
  const digit1 = calc(13);
  return digit1 === digits[1];
}

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('usuario') != null
        ? JSON.parse(localStorage.getItem('usuario'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('usuario', JSON.stringify(user));
    } else {
      localStorage.removeItem('usuario');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const dictionary = {
  approvalStatus: {
    aprovado: {
      label: 'Aprovado',
      bgColor: 'var(--success)',
      textColor: 'white',
    },
    reprovado: {
      label: 'Reprovado',
      bgColor: 'var(--danger)',
      textColor: 'white',
    },
    contestado: {
      label: 'Contestado',
      bgColor: 'var(--warning)',
      textColor: 'rgba(0, 0, 0, 0.6)',
    },
    pendente: {
      label: 'Pendente',
      bgColor: '#d2d2d2',
      textColot: '#666',
    },
  },
  checklistType: {
    locacao: 'Locação',
    servico: 'Serviço',
    indisponibilidade: 'Indisponibilidade',
    registro: 'Registro',
  },
};

export const normalizeDate = (value) => {
  if (value === null) return '-- - --';
  const date = new Date(value).toLocaleDateString('pt-BR');
  const time = new Date(value).toLocaleTimeString('pt-BR').slice(0, 5);

  return `${date} - ${time}`;
};

export const uploadFileToS3 = async (file) => {
  if (!file) return '';

  const type = file.type.split('/')[1];
  const key = `XML_BM_${v4()}.${type}`;

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: 'us-west-2',
  });

  const s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: { Bucket: 'images-telemedicina' },
  });

  const params = {
    ACL: 'public-read',
    Body: file,
    Bucket: 'images-telemedicina',
    ContentType: type.mime,
    Key: key,
  };

  const promise = s3.upload(params).promise();

  const { Location } = await promise;

  return Location;
};

export const normalizeFilters = (
  selectedType,
  selectedStatus,
  selectedPeriod
) => {
  if (
    selectedType != null &&
    selectedStatus != null &&
    selectedPeriod?.start != null &&
    selectedPeriod?.end != null
  ) {
    return `&status=${selectedStatus}&types=${selectedType}&startDate=${selectedPeriod?.start}&endDate=${selectedPeriod?.end}`;
  } else if (selectedType != null && selectedStatus != null) {
    return `&status=${selectedStatus}&types=${selectedType}`;
  } else if (
    selectedType != null &&
    selectedPeriod?.start != null &&
    selectedPeriod?.end != null
  ) {
    return `&types=${selectedType}&startDate=${selectedPeriod?.start}&endDate=${selectedPeriod?.end}`;
  } else if (
    selectedStatus != null &&
    selectedPeriod?.start != null &&
    selectedPeriod?.end != null
  ) {
    return `&status=${selectedStatus}&startDate=${selectedPeriod?.start}&endDate=${selectedPeriod?.end}`;
  } else if (selectedType != null) {
    return `&types=${selectedType}`;
  } else if (selectedStatus != null) {
    return `&status=${selectedStatus}`;
  } else if (selectedPeriod?.start != null && selectedPeriod?.end != null) {
    return `&startDate=${selectedPeriod?.start}&endDate=${selectedPeriod?.end}`;
  } else {
    return '';
  }
};


export const imageUpload = async (base64, name) => {

  const AWS = require('aws-sdk');

  // let S3_BUCKET = 'images-c3';
  let S3_BUCKET = 'images-telemedicina';

  console.log({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: 'us-west-2',
  })

  AWS.config.setPromisesDependency(bluebird);

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: 'us-west-2',
  });

  const s3 = new AWS.S3();

  const base64Data = new Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64');

  const type = base64.split(';')[0].split('/')[1];

  const params = {
    Bucket: S3_BUCKET,
    Key: `${name}.${type}`,
    Body: base64Data,
    ACL: 'public-read',
    ContentEncoding: 'base64',
    ContentType: `image/${type}`,
  }

  let location = '';
  let key = '';
  try {
    const { Location, Key } = await s3.upload(params).promise();
    location = Location;
    key = Key;
  } catch (error) {
    console.log('error ', error)
  }

  console.log(location, key);

  return location;

}